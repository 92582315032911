<template>
  <v-container
    id="alerts"
    fluid
    tag="section"
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-dialog
        v-model="carga"
        persistent
        width="350"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text class="text-h4 font-weight-light white--text">
            {{ textoMensaje }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-navigation-drawer
        absolute
        width="100%"
        permanent
      >
        <div
          class="top-main d-flex flex-column align-center justify-center"
        >
          <v-img
            src="assets/logo_la_n.png"
            max-width="250"
            @click="$router.push(
              { path: '/data' },
              () => {
                $emit('reload')
              },
            )"
          />
        </div>
        <div>
          <v-dialog
            v-model="dialogConsulta"
            width="95%"
            persistent
            transition="dialog-bottom-transition"
          >
            <base-material-card
              icon="mdi-seal"
              color="primary"
              :title="tituloDialog"
            >
              <br>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-autocomplete
                    v-model="filtroSeleccionado"
                    :items="itemsFiltro"
                    hide-selected
                    autofocus
                    item-text="nombre"
                    item-value="cod"
                    clearable
                    label="Busque el tipo de filtro"
                    placeholder="Escriba o seleccione el filtro"
                    @input="getFiltroSeleccionado()"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    tile
                    block
                    color="primary"
                    @click="leerData()"
                  >
                    <v-icon>
                      mdi-refresh
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    tile
                    block
                    color="green"
                    @click="generarExcel()"
                  >
                    <v-icon>
                      mdi-file-excel
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-spacer />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar..."
                    hide-details
                    class="ma-0"
                    style="max-width: 750px;"
                  />
                </v-col>
              </v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-card-text>
                  <v-data-table
                    :headers="headers"
                    :items="dataConsulta"
                    :search="search"
                    dense
                    light
                    :sort-by="['x_nom_instancia', 'nombre']"
                    :sort-desc="false"
                    class="elevation-1"
                    fixed-header
                    height="300px"
                    :loading="carga"
                    loading-text="Cargando, por favor espere"
                    :footer-props="{
                      'items-per-page-options': [10, 25, 50, -1],
                      'items-per-page-text': 'Mostrando:',
                      'items-per-page-all-text': 'Todos'
                    }"
                    :header-props="{
                      'sortByText': 'Ordenar por'
                    }"
                    no-data-text="No hay datos registrados"
                    no-results-text="No se encontraron resultados"
                  >
                    <template v-slot:[`item.f_inicio`]="{ item }">
                      <v-icon
                        color="green"
                      >
                        mdi-calendar
                      </v-icon>
                      {{ formatDate('DD/MM/YYYY', item.f_inicio) }}
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-col>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  block
                  @click="dialogConsulta = false"
                >
                  Cerrar
                </v-btn>
              </v-card-actions>
            </base-material-card>
          </v-dialog>
        </div>
        <v-row
          v-if="!carga"
          justify="center"
          no-gutters
        >
          <base-material-card
            color="primary"
            width="550"
            max-width="85%"
            icon="mdi-handcuffs"
            title="DELITOS"
          >
            <div class="mb-n4 mt-2 text-caption font-weight-light">
              Consulte sobre la cantidad de expedientes registrados por artículo y delito en un rango de fechas.
              <br>Para realizar la búsqueda seleccione fecha de inicio, fecha de fin y uno o más órganos jurisccionales para realizar la búsqueda, también puede hacer lo mismo con uno o más asistentes de audio
            </div>
            <v-card
              class="d-flex flex-column align-center justify-center"
            >
              <v-col
                cols="12"
                md="11"
              >
                <v-autocomplete
                  v-model="delitoSeleccionado"
                  :items="itemsDelito"
                  hide-no-data
                  hide-selected
                  item-text="articulo_delito"
                  label="Artículo y delito"
                  placeholder="Escriba o seleccione el artículo o delito a buscar"
                  prepend-icon="mdi-database-search"
                  :rules="regla"
                  chips
                  small-chips
                  return-object
                />
              </v-col>
              <v-col
                cols="12"
                md="11"
              >
                <v-dialog
                  ref="dialogFechaFin"
                  v-model="modalFechaFin"
                  :return-value.sync="fechasConsulta"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechasConsulta"
                      label="Fechas a consultar"
                      prepend-icon="mdi-calendar"
                      readonly
                      :rules="regla"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="fechasConsulta"
                    scrollable
                    range
                    :rules="regla"
                    locale="es"
                    color="primary"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="modalFechaFin = false"
                    >
                      CANCELAR
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialogFechaFin.save(fechasConsulta)"
                    >
                      GUARDAR
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-btn
                  color="primary"
                  block
                  @click="consultarData()"
                >
                  consultar
                </v-btn>
              </v-col>
            </v-card>
          </base-material-card>
        </v-row>
        <div
          class="text-body-2 white--text text-center"
          @click="$router.push('/cerrar_sesion')"
        >
          {{ new Date().getFullYear() }} &copy; CSJLA
        </div>
      </v-navigation-drawer>
    </v-form>
  </v-container>
</template>

<script>
  import axios from 'axios'
  const lodash = require('lodash')
  export default {
    name: 'Menu',
    data: () => ({
      carga: true,
      model: null,
      search: '',
      items: [],
      itemsDelito: [],
      delitoSeleccionado: '',
      regla: [(v) => !!v || 'Campo requerido'],
      fechasConsulta: [],
      itemsFiltro: [{ nombre: 'Juzgado', cod: 1 }, { nombre: 'Distrito', cod: 2 }, { nombre: 'Provincia', cod: 3 }, { nombre: 'Total', cod: 4 }],
      fechaInicio: '',
      fechaFin: '',
      modalFechaInicio: false,
      modalFechaFin: false,
      dialogConsulta: false,
      dataConsulta: [],
      expanded: [],
      listaSecretarios: [],
      dataAgrupada: [],
      dataInicial: [],
      filtroSeleccionado: '',
      textoMensaje: 'CARGANDO PÁGINA',
      headers: [],
      delitoActual: '',
      headersIni: [{
                     text: 'Juzgado',
                     value: 'x_nom_instancia',
                     width: 380,
                   },
                   {
                     text: 'Distrito',
                     value: 'distrito',
                   },
                   {
                     text: 'Provincia',
                     value: 'provincia',
                   },
                   {
                     text: 'Exp.',
                     value: 'x_formato',
                     align: 'center',
                     width: 240,
                   },
                   {
                     text: 'Fecha',
                     value: 'f_inicio',
                     align: 'center',
                   }],
      tipoData: 1,
      tituloDialog: '',
      switchSecretario: false,
      secretarioSeleccionado: '',
    }),
    created () {
      this.dataUsuario = JSON.parse(localStorage.getItem('usuarioDataCSJLA'))
      if (!this.dataUsuario) {
        localStorage.removeItem('usuarioDataCSJLA')
        this.$router.push('/')
      }
      this.$store.commit('SET_VALOR', true)
      this.itemsDelito = [{ id: '02', nombre: 'SALA SUPERIOR' }, { id: '03', nombre: 'JUZGADO ESPECIALIZADO' }, { id: '04', nombre: 'JUZGADO MIXTO' }, { id: '05', nombre: 'JUZGADO DE PAZ LETRADO' }]
      setTimeout(() => { this.carga = false }, 1500)
      this.leerDelitos()
    },
    mounted () {
      window.scrollTo(0, 0)
    },
    methods: {
      getFiltroSeleccionado () {
        this.tipoData = 2
        if (this.filtroSeleccionado === 1) {
          this.generarAgrupacion(lodash.groupBy(this.dataInicial, item => `"${item.x_nom_instancia}"`), 'juzgado')
        } else if (this.filtroSeleccionado === 4) {
          this.generarAgrupacion(lodash.groupBy(this.dataInicial, item => `"${item.c_delito}"`), 'total')
        } else if (this.filtroSeleccionado === 2) {
          this.generarAgrupacion(lodash.groupBy(this.dataInicial, item => `"${item.distrito}"`), 'distrito')
        } else if (this.filtroSeleccionado === 3) {
          this.generarAgrupacion(lodash.groupBy(this.dataInicial, item => `"${item.provincia}"`), 'provincia')
        }
      },
      limpiarCampos () {
        this.delitoSeleccionado = ''
        this.secretarioSeleccionado = ''
        this.filtroSeleccionado = ''
      },
      leerData () {
        this.headers = this.headersIni
        this.carga = false
        this.dataConsulta = this.dataInicial
        this.dialogConsulta = true
        this.tipoData = 1
        this.filtroSeleccionado = ''
      },
      generarExcel () {
        if (this.tipoData === 1) {
          this.itemsXls = this.dataConsulta.map(obj => {
            const rObj = {}
            rObj.Juzgado = obj.x_nom_instancia
            rObj.Expediente = obj.x_formato
            rObj.Fecha = obj.estado
            return rObj
          })
        } else {
          this.itemsXls = this.dataConsulta.map(obj => {
            const rObj = {}
            rObj.Nombre = obj.nombre
            rObj.Cantidad = obj.cantidad
            return rObj
          })
        }
        this.exportarExcel()
      },
      exportarExcel () {
        const XLSX = require('xlsx')
        const data = XLSX.utils.json_to_sheet(this.itemsXls)
        const workbook = XLSX.utils.book_new()
        const filename = 'reporteDelitos'
        XLSX.utils.book_append_sheet(workbook, data, filename)
        XLSX.writeFile(workbook, `${filename}.xlsx`)
      },
      consultarData () {
        if (this.fechasConsulta.length === 0 || this.fechasConsulta.length === 1) {
          alert('SELECCIONE LAS FECHAS CORRECTAMENTE')
        } else {
          if (this.$refs.form.validate()) {
            this.textoMensaje = 'CARGANDO INFORMACIÓN DEL SISTEMA'
            this.headers = this.headersIni
            this.carga = true
            const data = {
              fecha1: this.formatDate('YYYY-MM-DD', this.fechasConsulta[0]),
              fecha2: this.formatDate('YYYY-MM-DD', this.fechasConsulta[1]),
              delito: this.delitoSeleccionado.c_delito,
              articulo: this.delitoSeleccionado.c_item,
            }
            axios
              .post(
                `${window.__env.dataCfg.urlApiSIJWS}expedientes/expedientesDelito`, data,
              )
              .then((response) => {
                if (response.data.status === 'shido') {
                  this.tituloDialog = 'Delito: ' + this.delitoSeleccionado.articulo_delito + ' Periodo ' + this.formatDate('DD/MM/YYYY', this.fechasConsulta[0]) + ' - ' + this.formatDate('DD/MM/YYYY', this.fechasConsulta[1])
                  this.carga = false
                  this.delitoActual = this.delitoSeleccionado.articulo_delito
                  const result = response.data.data
                  this.dataConsulta = response.data.data
                  const grupoSec = this.groupBy(result, data => data.x_usuario)
                  this.listaSecretarios = Array.from(grupoSec.values())
                  this.dataAgrupada = lodash.groupBy(result, 'x_usuario')
                  this.dialogConsulta = true
                  this.dataInicial = this.dataConsulta
                  this.tipoData = 1
                  this.delitoSeleccionado = ''
                  this.secretarioSeleccionado = ''
                  this.filtroSeleccionado = ''
                } else {
                  alert('NO SE ENCONTRÓ LA INFORMACIÓN DE DE LOS JUZGADOS EN LAS FECHAS SELECCIONADAS')
                  this.carga = false
                }
              })
              .catch((e) => {
                alert('ERROR EN LA CONSULTA A LA BASE DE DATOS, INTENTE NUEVAMENTE MÁS TARDE')
                this.carga = false
                console.log(e); // eslint-disable-line
              })
          }
        }
      },
      leerTodo () {
        this.dataConsulta = this.dataInicial
      },
      leerDelitos () {
        axios
          .post(
            `${window.__env.dataCfg.urlApiSIJWS}expedientes/articulosPorDelito`,
          )
          .then((response) => {
            if (response.data.status === 'shido') {
              this.tituloDialog = 'Información de la consulta realizada del periodo ' + this.formatDate('DD/MM/YYYY', this.fechasConsulta[0]) + ' y ' + this.formatDate('DD/MM/YYYY', this.fechasConsulta[1])
              this.carga = false
              this.itemsDelito = response.data.data
            } else {
              alert('NO SE ENCONTRÓ LA INFORMACIÓN DE DE LOS JUZGADOS EN LAS FECHAS SELECCIONADAS')
              this.carga = false
            }
          })
          .catch((e) => {
            alert('ERROR EN LA CONSULTA A LA BASE DE DATOS, INTENTE NUEVAMENTE MÁS TARDE')
            this.carga = false
                console.log(e); // eslint-disable-line
          })
      },
      generarAgrupacion (arrayGroup, agruparPor) {
        this.dataConsulta = []
        this.headers = [
          {
            text: 'Nombre',
            value: 'nombre',
          },
          {
            text: 'Cantidad',
            value: 'cantidad',
          },
        ]
        const arrayFinal = Object.values(arrayGroup)
        for (var i = 0; i < arrayFinal.length; i++) {
          var sumatoria = 0
          for (var j = 0; j < arrayFinal[i].length; j++) {
            sumatoria = sumatoria + 1
          }
          if (agruparPor === 'juzgado') {
            this.dataConsulta.push({ nombre: arrayFinal[i][0].x_nom_instancia, cantidad: sumatoria })
          } else if (agruparPor === 'total') {
            this.dataConsulta.push({ nombre: this.delitoActual, cantidad: sumatoria })
          } else if (agruparPor === 'distrito') {
            this.dataConsulta.push({ nombre: arrayFinal[i][0].distrito, cantidad: sumatoria })
          } else if (agruparPor === 'provincia') {
            this.dataConsulta.push({ nombre: arrayFinal[i][0].provincia, cantidad: sumatoria })
          }
        }
      },
      cerrarSesion () {
        var logOut = confirm('¿Desea cerrar sesión?')
        if (logOut === true) {
          localStorage.removeItem('usuarioDataCSJLA')
          localStorage.removeItem('accesoDataJud')
          this.$router.push('/')
        }
      },
      groupBy (list, keyGetter) {
        const map = new Map()
        list.forEach((item) => {
          const key = keyGetter(item)
          const collection = map.get(key)
          if (!collection) {
            map.set(key, [item])
          } else {
            collection.push(item)
          }
        })
        return map
      },
      formatDate (template, date) {
        const specs = 'YYYY:MM:DD:HH:mm:ss'.split(':')
        date = new Date(
          date || Date.now() - new Date().getTimezoneOffset() * 6e4,
        )
        return date
          .toISOString()
          .split(/[-:.TZ]/)
          .reduce(
            (template, item, i) =>
            // eslint-disable-line
              template.split(specs[i]).join(item),
            template,
          )
      },
    },
  }
</script>
